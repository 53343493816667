import { createStore } from 'vuex';

import auth from './auth';
import users from './users';
import labels from './labels';
import settings from './settings';
import locations from './locations';
import purchases from './purchases';
import sales from './sales';


export default createStore({
  modules: {
    auth,
    users,
    labels,
    settings,
    locations,
    purchases,
    sales,
  }
});