import axios from 'axios';
import router from '../router';

const state = {
  locations: [],
  // children: [],
};

const mutations = {
  SET_LOCATIONS(state, locations) {
    state.locations = locations;
  },
  /*
  SET_CHILDRENS(state, children) {
    state.children = children;
  },
  */
};

const actions = {
  async fetchLocations({ commit }, { search }) {
    console.log('Fetching locations with search term:', search);
    const userToken = localStorage.getItem('userToken'); 
    if (!userToken) {
      router.push({ name: 'login' });
      return;
    }

    try {
      const response = await axios.get(`https://api.datafangst.dk/api/locations?search=${search}`,
        { headers: { Authorization: `Bearer ${userToken}` } }
      );
      // console.log('Location result with search:', search);
      console.log('Location response:', response.data);
      commit('SET_LOCATIONS', response.data.locations);
    } catch (error) {
      console.error('Error fetching locations:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  },
  /*
  async fetchChildLocations({ commit }, { search }) {
    console.log('Fetching location children with search term:', search);
    const userToken = localStorage.getItem('userToken'); 
    if (!userToken) {
      router.push({ name: 'login' });
      return;
    }

    try {
      const response = await axios.get(`https://api.datafangst.dk/api/locations/children/10001?search=${search}`,
        { headers: { Authorization: `Bearer ${userToken}` } }
      );
      console.log('Location result with search:', search);
      commit('SET_CHILDREN', response.data.locations);
    } catch (error) {
      console.error('Error fetching child locations:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  },
  */
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};