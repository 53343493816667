<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer">
      <v-list-item>
        <v-list-item-title class="title text-center pt-8 pb-5">
          <a href="/"  style="font-size: 18px; text-decoration: none;" :class="{ 'dark': isDark, 'light-link': !isDark }"><img :src="logoSrc" alt="Logo" class="logo-src" /></a>
        </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
          <svg-icon type="mdi" :path="item.icon"></svg-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <v-app-bar
        app
        dark 
        prominent 
        color="teal-darken-2" 
        height="120"
        image="top_pict.jpg"
    >
        <!-- Image slot -->
        <template v-slot:image>
            <v-img gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"></v-img>
        </template>

        <!-- Prepend slot -->
        <template v-slot:prepend>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        </template>

        <!-- Title slot -->
        <v-app-bar-title><a href="/" style="font-size: 24px; text-decoration: none; color: #EEE;">{{ apptitle }}</a></v-app-bar-title>

        <!-- Spacer -->
        <v-spacer></v-spacer>

        <!-- Temaskifter -->
        <v-btn v-bind="props" icon @click="toggleTheme" class="theme-change"><svg-icon type="mdi" :path="mdiThemeLightDark"></svg-icon></v-btn>
        
        <!-- Avatar -->
        <v-tooltip text="Account" location="top">
          <template v-slot:activator="{ props }">
            <v-avatar 
              v-bind="props" 
              color="primary" 
              size="36px" 
              @click="navigateToSettings"
              style="cursor: pointer;"
            >
              {{ userInitials }}  
            </v-avatar>
          </template>
        </v-tooltip>

        <!-- Tooltip with Logout button -->
        <v-tooltip text="Logout" location="top">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon @click="handleLogout"><v-icon>mdi-logout</v-icon></v-btn>
          </template>
        </v-tooltip>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useTheme } from 'vuetify';
import { useStore } from 'vuex';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiMonitorDashboard, mdiPrinter, mdiArchiveMarkerOutline, mdiInvoiceTextMultipleOutline, mdiInformationVariant, mdiThemeLightDark } from '@mdi/js';

import logoDark from '@/assets/logo-dark.png'; 
import logoLight from '@/assets/logo-light.png';

const router = useRouter();
const drawer = ref(null);
const theme = useTheme();
const isDark = ref(false);
const store = useStore();

const user = localStorage.getItem('username');
const apptitle = process.env.VUE_APP_NAME;

// logo 
const logoSrc = computed(() => {
  return isDark.value ? logoDark : logoLight;
});

// Check localStorage om der er en tema værdi
onMounted(() => {
  const savedTheme = localStorage.getItem('theme');
  if (savedTheme) {
    theme.global.name.value = savedTheme;
    isDark.value = (savedTheme === 'dark');
  }
});

// Function to navigate to the settings page
const navigateToSettings = () => {
  router.push('/settings');
};

// Function to toggle the theme
const toggleTheme = () => {
  isDark.value = !isDark.value;
  const newTheme = isDark.value ? 'dark' : 'light';
  
  // Updater temavalg
  theme.global.name.value = newTheme;
  localStorage.setItem('theme', newTheme);
};

// Computed property til bruger initialer
const userInitials = computed(() => {
  const realname = localStorage.getItem('username') || '';
  return realname
    .split(' ')
    .map(name => name.charAt(0))
    .join('');
});

  const items = [
    { title: "Dashboard", icon: mdiMonitorDashboard, to: "/" },
    { title: "Label print", icon: mdiPrinter, to: "/labels" },
    { title: "Lokationer", icon: mdiArchiveMarkerOutline, to: "/locations" },
    { title: "Indkøbsordre", icon: mdiInvoiceTextMultipleOutline, to: "/purchases" },
    { title: "Salgsordre", icon: mdiInvoiceTextMultipleOutline, to: "/sales" },
    { title: "Om", icon: mdiInformationVariant, to: "/about" },
  ];

  // Handle logout functionality
  const handleLogout = () => {
    console.log('handleLogout method called');
    store.dispatch('auth/logout') 
      .then(() => {
        const router = useRouter();
        router.push({ name: 'login' });
      })
      .catch((error) => {
        console.error('Error logging out:', error);
      });
  };

</script>


<style scoped>
.dark-link {
  color: #888888;
}

.light-link {
  color: #333333;
}

.theme-change {
  background: #3F51B5;
  width: 2.2em; 
  height: 2.2em; 
  margin-right: 0.75em;
}

.logo-src {
  width: 180px;
}
</style>