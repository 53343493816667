import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      const userToken = localStorage.getItem('userToken');
      if (userToken) {
        next();
      } else {
        next('/login'); // Redirect to login if user token doesn't exist
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/labels',
    name: 'labels',
    component: () => import(/* webpackChunkName: "labels" */ '../views/LabelView.vue')
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import(/* webpackChunkName: "locations" */ '../views/LocationsView.vue')
  },
  {
    path: '/purchases',
    name: 'purchases',
    component: () => import(/* webpackChunkName: "purchases" */ '../views/PurchasesView.vue')
  },
  {
    path: '/purchaseOrderDetail/:id',
    name: 'orderdetail',
    component: () => import(/* webpackChunkName: "orderdetails" */ '../views/PurchaseDetailView.vue')
  }, 
  {
    path: '/sales',
    name: 'sales',
    component: () => import(/* webpackChunkName: "sales" */ '../views/SalesView.vue')
  },
  {
    path: '/salesOrderDetail/:id',
    name: 'salesdetail',
    component: () => import(/* webpackChunkName: "salesdetails" */ '../views/SalesDetailView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
