import axios from 'axios';

// State
const state = () => ({
  settings: {
    shipping: []
  }
});

// Mutations
const mutations = {
    setSettings(state, settings) {
      state.settings.shipping = settings; // Set the shipping array directly
    },
    updateShippingSetting(state, { index, key, value }) {
      state.settings.shipping[index][key] = value;
    }
  };

// Actions
const actions = {
    async fetchSettings({ commit }) {
      const userToken = localStorage.getItem('userToken'); 
      if (!userToken) {
        // Redirect to login page if token is not found
        router.push({ name: 'login' });
        return;
      }
      
      const savedId = localStorage.getItem('userId');
      if (!savedId) {
        console.error('No user ID found in local storage.');
        return; // Exit early if savedId is not present
      }
  
      // const user_id = savedId.split('_')[0]; // Safely splits savedId
  
      try {
        const response = await axios.get(`https://api.datafangst.dk/api/shipper/${savedId}`, {
          headers: { Authorization: `Bearer ${userToken}` }
        });
  
        console.log('API Response:', response.data); // Log the full response to inspect
  
        // Assuming the response contains a 'shipper' array
        if (response.data && response.data.shipper && response.data.shipper.length > 0) {
          commit('setSettings', response.data.shipper); // Commit the 'shipper' data directly
        } else {
          console.error('No shipping settings found in API response.');
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    },
    updateSetting({ commit }, payload) {
      commit('updateShippingSetting', payload);
    }
  };

// Getters
const getters = {
  shippingSettings: (state) => state.settings.shipping
};

// Export
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};