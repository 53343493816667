
import axios from 'axios';
import router from '../router';


const state = {
  purchases: [],
  selectedOrder: null,
};

const mutations = {
  SET_PURCHASES(state, purchases) {
    state.purchases = purchases;
  },
  SET_SELECTED_ORDER(state, selectedOrder) {
    state.selectedOrder = selectedOrder; 
  }
};
  
  const actions = {
    async fetchPurchases({ commit }, { search }) {
      // console.log('Fetching products with search term:', search);
      const userToken = localStorage.getItem('userToken'); 
      if (!userToken) {
          // Redirect to login page if token is not found
          router.push({ name: 'login' });
          return;
      }
  
      try {
        const response = await axios.get(`https://api.datafangst.dk/api/rackbeatindkoeb?search=${search}&limit=200`, { headers: { Authorization: `Bearer ${userToken }` }, } );
        console.log(response.data.purchase_orders);
        commit('SET_PURCHASES', response.data.purchase_orders);
      } catch (error) {
        console.error('Error fetching purchases:', error);
        throw error; // Rethrow the error to handle it in the component
      }
    },
    async fetchOrderById({ commit }, orderId) {
      const userToken = localStorage.getItem('userToken'); 
      if (!userToken) {
          router.push({ name: 'login' });
          return;
      }
    
      try {
        const response = await axios.get(`https://api.datafangst.dk/api/rackbeatindkoeb/${orderId}`, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        
        console.log(`Fetched Order Result:`, response.data.purchase_order);
        commit('SET_SELECTED_ORDER', response.data.purchase_order); // Make sure it matches API structure
      } catch (error) {
        console.error('Error fetching order:', error);
        throw error;
      }
    }
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  };